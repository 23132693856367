import React, { useRef, useEffect, useState } from "react";
import SEO from "../components/seo";
import { navigate } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import abstract1 from "../images/abstract01.jpg";
import abstract2 from "../images/abstract02.jpg";
import abstract3 from "../images/abstract03.jpg";
import abstract4 from "../images/abstract04.jpg";

const IndexPage = () => {
    const SampleNextArrow = props => {
        const { className, onClick, style } = props;
        return (
            <div
                tabIndex="0"
                role="button"
                onKeyDown={e => {
                    if (e.keyCode === 32) {
                        e.preventDefault();
                        onClick();
                    }
                }}
                onClick={onClick}
                className={className}
                style={{
                    ...style,
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20.887 36.117"
                    style={{
                        fill: "none",
                        stroke: "#404040",
                        strokeWidth: "5px",
                        width: "20px",
                        height: "auto",
                    }}>
                    <path d="M-845.527,1580l16.644,16.644-16.644,16.644" transform="translate(846.942 -1578.586)" />
                </svg>
            </div>
        );
    };

    const SamplePrevArrow = props => {
        const { className, style, onClick } = props;
        return (
            <div
                tabIndex="0"
                role="button"
                onKeyDown={e => {
                    if (e.keyCode === 32) {
                        e.preventDefault();
                        onClick();
                    }
                }}
                onClick={onClick}
                className={className}
                style={{
                    ...style,
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20.887 36.117"
                    style={{
                        fill: "none",
                        stroke: "#404040",
                        strokeWidth: "5px",
                        transform: "rotate(180deg)",
                        width: "20px",
                        height: "40px",
                    }}>
                    <path d="M-845.527,1580l16.644,16.644-16.644,16.644" transform="translate(846.942 -1578.586)" />
                </svg>
            </div>
        );
    };

    const sliderBig = useRef(null);
    const sliderNav = useRef(null);

    const [bigSlideRef, setBigSlideRef] = useState(null);
    const [navSlideRef, setNavSlideRef] = useState(null);

    useEffect(() => {
        setBigSlideRef(sliderBig.current);
        setNavSlideRef(sliderNav.current);
    }, []);

    let settings = {
        infinite: true,
        lazyLoad: "ondemand",
        asNavFor: navSlideRef,
        ref: sliderBig,
        arrows: false,
        className: "sliderGalery",
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: true,
                },
            },
        ],
    };

    let settingsNav = {
        asNavFor: bigSlideRef,
        ref: sliderNav,
        slidesToShow: 5,
        swipeToSlide: true,
        arrows: true,
        focusOnSelect: true,
        infinite: true,
        lazyLoad: "ondemand",
        className: "navSliderGalery",
        speed: 500,
        slidesToScroll: 1,
        autoplay: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    return (
        <>
            <SEO title="Home" />
            <section className="banner py-8 px-12 lg:px-6 lg:pt-20">
                <div className="container mx-auto">
                    <div className="flex flex-col lg:w-1/2 lg:pr-16">
                        <p className="mb-5 text-cinza_claro font-bold lg:text-2xl lg:mb-10">
                            Procurando um atendimento mais rápido e sem necessidade de contato físico, a equipe do
                            cartório preparou uma série de formulários para adiantar os processo e solicitações
                        </p>
                        <div>
                            <button
                                onClick={() => navigate("/formularios/")}
                                className="px-4 py-2 bg-cinza_claro rounded text-azul_base">
                                Preencher Formulários
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-8 px-12 lg:py-16 lg:px-6">
                <div className="container mx-auto  text-azul_base ">
                    <h1 className="leading-none text-2xl font-bold mb-4 lg:text-4xl lg:mb-12">
                        Serviços que oferecemos
                    </h1>
                    <div className="flex flex-col lg:flex-row lg:justify-start">
                        <p className="text-base text-preto leading-none lg:text-vermelho lg:w-1/3 lg:text-lg lg:leading-tight lg:mr-12">
                            O 21º Cartório de Registro Civil das Pessoas Naturais da Saúde, da Capital de São Paulo é
                            uma serventia extrajudicial responsável pela prestação de serviços públicos que garantem a
                            autenticidade, eficácia, publicidade e a segurança jurídica de todos os clientes (art. 1º da
                            Lei 6.015/1973 e art. 1º da Lei 9.835/94). Assim, prezamos pelos Princípios norteadores dos
                            Registros Públicos
                            <br />
                            <br />
                            Informamos que os serviços prestados em qualquer cartório possuem um valor chamado de
                            emolumentos, que é a remuneração paga pelos respectivos atos. Os emolumentos são definidos
                            na Lei Federal 10.169/2000 e na Lei Estadual nº. 11.331/2002, em São Paulo os valores são
                            corrigidos anualmente pelo valor da UFESP.
                        </p>
                        <ul className="text-base list-disc list-inside font-bold mt-4 lg:text-lg lg:w-2/3 lg:mt-0 lg:ml-12 lg:h-128 lg:flex lg:flex-col lg:flex-wrap lg:content-around">
                            <li className="lg:mb-4">Abertura de firmas</li>
                            <li className="lg:mb-4">Reconhecimento de firmas</li>
                            <li className="lg:mb-4">Autenticação de documentos</li>
                            <li className="lg:mb-4">Procuração Pública</li>
                            <li className="lg:mb-4">Apostilamento</li>
                            <li className="lg:mb-4">Alteração Patromínico Familiar</li>
                            <li className="lg:mb-4">Averbações em geral</li>
                            <li className="lg:mb-4">Registro de Nascimento</li>
                            <li className="lg:mb-4">Registro de Casamento</li>
                            <li className="lg:mb-4">Registro de Óbito</li>
                            <li className="lg:mb-4">Segunda Via de Certidão em breve relato e inteiro teor</li>
                            <li className="lg:mb-4">Alteração de Nome e Sexo</li>
                            <li className="lg:mb-4">
                                Reconhecimento de Paternidade e Maternidade (biológico e socioafetivo)
                            </li>
                            <li className="">Retificações de registros</li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* <section className="mb-24 mt-8">
                <div className="container mx-auto px-12 lg:px-8">
                    <h3 className="text-azul_claro text-xl mb-4 lg:text-2xl">Galeria de imagens</h3>
                    <div>
                        <Slider {...settings}>
                            <div>
                                <img className="h-auto w-full" src={abstract1} alt="Imagem exemplo" />
                            </div>
                            <div>
                                <img className="h-auto w-full" src={abstract2} alt="Imagem exemplo" />
                            </div>
                            <div>
                                <img className="h-auto w-full" src={abstract3} alt="Imagem exemplo" />
                            </div>
                            <div>
                                <img className="h-auto w-full" src={abstract4} alt="Imagem exemplo" />
                            </div>
                            <div>
                                <img className="h-auto w-full" src={abstract4} alt="Imagem exemplo" />
                            </div>
                            <div>
                                <img className="h-auto w-full" src={abstract4} alt="Imagem exemplo" />
                            </div>
                        </Slider>
                        <Slider {...settingsNav}>
                            <div className="p-2">
                                <img src={abstract1} alt="Imagem exemplo" />
                            </div>
                            <div className="p-2">
                                <img src={abstract2} alt="Imagem exemplo" />
                            </div>
                            <div className="p-2">
                                <img src={abstract3} alt="Imagem exemplo" />
                            </div>
                            <div className="p-2">
                                <img src={abstract4} alt="Imagem exemplo" />
                            </div>
                            <div className="p-2">
                                <img src={abstract4} alt="Imagem exemplo" />
                            </div>
                            <div className="p-2">
                                <img src={abstract4} alt="Imagem exemplo" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default IndexPage;
